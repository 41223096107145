import {useWindowSize} from "@vueuse/core";

export default function useScreenSize() {
  const { width, height } = useWindowSize();

  const isMobile = computed(() => Number.isInteger(width.value) && width.value <= 720);
  const isTablet = computed(() => Number.isInteger(width.value) && width.value <= 1024);
  const isLaptop = computed(() => Number.isInteger(width.value) && width.value <= 1440);
  const isDesctop = computed(() => Number.isInteger(width.value) && width.value <= 1440);

  return {
    width,
    height,
    isMobile,
    isTablet,
    isLaptop,
    isDesctop
  };
}
